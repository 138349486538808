@import "./utils/less/reset.less";
@import "./utils/less/variable.less";
@import "adesign-react/libs/style.css";
@import "adesign-react/themes/default.css";
html {
  height: 100%;
}

body {
  height: 100%;
  color: #808080;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  background-color: #fff;
  --Color: #181819b3;
  --Color1: rgba(255, 255, 255, 1);
  --Color2: rgba(252, 117, 13, 1);
  --Base: #fa7600;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #334155;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #334155;
  --color-prettylights-syntax-markup-bold: #334155;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #334155;
  --color-fg-muted: #57606a;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: #ffffff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsla(210, 18%, 87%, 1);
  --color-neutral-muted: rgba(175, 184, 193, 0.2);
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #cf222e;
}

#nprogress .bar {
  background: #fa8c16 !important; //自定义颜色

  .peg {
    box-shadow: none;
  }
}

.ant-popover-inner {
  padding: 6px 12px !important;
}

.ant-btn {
  &:hover {
    opacity: 0.8;
  }
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* 滚动条轨道样式 */
::-webkit-scrollbar-track {
  background: transparent;
}

.ant-tree-list-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg-color) !important;
}

.ant-tree-list-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-bg-color) !important;
}

.rc-virtual-list-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg-color) !important;
}

.rc-virtual-list-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-bg-color) !important;
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-bg-color);
  border-radius: 4px;
}

/* 滚动条轨道hover样式 */
::-webkit-scrollbar-track:hover {
  background-color: transparent !important;
}

/* 滚动条滑块hover样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-bg-color);
}

// @media (max-width: 750px) {
//   html, body {
//     font-size: 24px;
//   }
// }

// @media (max-width: 375px) {
//   html, body {
//     font-size: 12px;
//   }
// }

.ant-breadcrumb {
  font-size: 1em;

  li {
    color: var(--color-title-content-opacity) !important;
    .ant-breadcrumb-link {
      color: var(--color-title-content-opacity) !important;
      cursor: pointer;
    }
    &:last-child {
      .ant-breadcrumb-link {
        color: var(--color-title-content) !important;
      }
    }
  }
}

html,
body {
  font-size: calc(12px + 12 * ((100vw - 375px) / 375));
}

@media (min-width: 750px) {
  html,
  body {
    font-size: 12px;
  }
}

.doc_jsonschema_warp {
  pre {
    border: none !important;
  }
}
.ant-popover {
  li {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    &:hover {
      color: var(--color-primary) !important;
      background-color: var(--select-hover-color) !important;
      cursor: pointer;
      button {
        color: var(--color-primary) !important;
      }
    }
  }
}
